import { LinkButton } from "./LinkButton";

export function GamePreview({ height, image, gameTitle, fontFamily, tagline, letterSpacing, wordSpacing, fontSize, url }) {
    return (
        <div>
            <div
                className="game-preview-background"
                style={{
                    "backgroundImage": `linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 0.85)), url(${image})`,
                    "minHeight": height
                }}>
                <div className="text-container">
                    <span
                        className="game-title-text"
                        style={{
                            "fontFamily": fontFamily,
                            "wordSpacing": wordSpacing,
                            "letterSpacing": letterSpacing,
                            "fontSize": fontSize
                        }}>
                        {gameTitle}
                    </span>
                    <span className="game-tagline-text">{tagline}</span>

                    <LinkButton
                        text="Learn more"
                        url={url}
                    />
                </div>
            </div>
        </div>
    );
}