export function FooterButton({ url, icon_path }) {
    return (
        <div className="footer-button-bg">
            <img
                className="footer-button-icon"
                src={icon_path}
                onClick={() => window.location = url}
            />
        </div>
    )
}