import { Link } from "react-router-dom";
import { FooterButton } from "./FooterButton";

export function Footer() {
    return (
        <div className="footer">
            <div id="footer-button-container">
                <FooterButton icon_path="img/icons/email.svg" url="mailto:support@nooblesstudio.com" />
                <FooterButton icon_path="img/icons/instagram.svg" url="https://www.instagram.com/noobles.studio/" />
                <FooterButton icon_path="img/icons/youtube.svg" url="https://www.youtube.com/@noobles_studio" />
            </div>
            <span className="footer-text">© 2025 Noobles Studio. All rights reserved.</span>
            <Link className="footer-text" to="/privacy-policy">Privacy policy</Link>
        </div>
    );
}