import '../styles/App.css';

export function PrivacyPolicy() {
    return (
        <div className="text-container">
            <h1 className="header-dark">Privacy Policy</h1>
            <p className="text-dark">
                This is the legal bit that we have to have on the website.
            </p>
            <p className="text-dark">
                We do not collect/store any data from you in our games or website. However, the platforms that we publish to (e.g. Xbox,
                Steam, etc.) may collect data for their own purposes. Please see their privacy policies for more information.
            </p>
            <p className="text-dark">
                Even though we probably won't, we reserve the right to update the Privacy Policy at any time. An update will be posted on the website
                should this happen. Please contact <strong>support@nooblesstudio.com</strong> with any questions or concerns.
            </p>
        </div>
    );
}