import '../styles/App.css';
import { GamePreview } from '../components/GamePreview'

export function Home() {
  return (
    <div>
      <GamePreview
        image="img/stasis-valley/bg.png"
        height="95vh"
        gameTitle="STASIS VALLEY"
        tagline="Awaken a valley frozen in time. Coming early 2025 to all platforms."
        url="/stasis-valley"
        fontFamily="IntroCond"
        wordSpacing="0.325em"
        letterSpacing="0.1em"
        fontSize="9.5vh"
      />

      <GamePreview
        image="img/the-taller-i-grow/bg.png"
        height="95vh"
        gameTitle="[the taller i grow]"
        tagline="Grow taller to solve puzzles. Available now."
        url="https://www.xbox.com/en-US/games/store/the-taller-i-grow/9p33qjg8cmm6"
        fontFamily="FFFForward"
        fontSize="7vh"
      />
    </div>
  );
}