import Carousel from 'react-multi-carousel';
import { LinkButton } from '../components/LinkButton';
import '../styles/App.css'
import '../styles/StasisValley.css'

export function StasisValley() {
    return (
        <div>
            <div id="background-isolated">
                <div id="subject-isolated" style={{ "backgroundPosition": `center` }}>
                    <div id="foreground-isolated">
                        <span id="stasis-valley-title">
                            STASIS VALLEY
                        </span>
                    </div>
                </div>
            </div>

            <div id="content-background">
                <div className="text-light">
                    Stasis Valley is an action-platformer which follows Adam, a young man who descends into a world frozen in time. Uncover its secrets,
                    experience a story about grief, and confront the dangers lurking within the valley.
                </div>

                <div className="text-light">
                    Demo for PC/Mac out now! Full game releasing early 2025.
                </div>

                <LinkButton text="Play the demo and wishlist on Steam" url="https://store.steampowered.com/app/1460080/Stasis_Valley/" />

                {/* <h1 className="header-light">Trailer</h1>
                <iframe
                    src="https://www.youtube.com/embed/kc2G3EsGlk8?si=BEtyqWPDFAzmF4rK"
                    title="YouTube video player"
                    border="0"
                    id="embed-trailer"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                /> */}

                <h1 className="header-light">Credits</h1>

                <div className="text-light">
                    <strong>Programming, art:</strong><br />
                    Dan Donchuk
                </div>

                <div className="text-light">
                    <strong>Music:</strong><br />
                    Mike Eramo
                </div>

                <div className="text-light">
                    <strong>Sound effects:</strong><br />
                    Kiwicake Audio (David Lin & Andrew Mortega), Sai
                </div>

                <div className="text-light">
                    <strong>Player animation:</strong><br />
                    lhama_jeff
                </div>
            </div>
        </div>
    );
}